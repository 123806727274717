import {FC} from "react"
import {useTranslation} from "react-i18next";
import api from '../api'
import {useNavigate} from 'react-router'
import {Text, Card, Image, Flex, Stack} from '@mantine/core'
import PasswordResetForm from '../forms/PasswordResetForm'
import {PasswordReset} from '../entities/PasswordReset'
import Keyhole from '../keyhole.svg'

const PasswordResetView: FC = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const handlePasswordReset = async (passwordReset: PasswordReset) => {
    try {
      await api.sendPasswordResetEmail(passwordReset.email)
      navigate('/end', {state: {success: t('success_password_reset_email_sent')}})
    } catch (e: any) {
      navigate('/end', {state: {error: t(e.message)}})
    }
  }
  return (
    <Flex h="100vh" justify="center" align="center">
      <Card shadow="lg" withBorder>
        <Stack align="center">
          <Image src={Keyhole} maw={30}/>
          <Text size="lg">{t('reset_password')}</Text>
          <PasswordResetForm onOk={handlePasswordReset} onCancel={() => navigate(-1)}/>
        </Stack>
      </Card>
    </Flex>
  )
}

export default PasswordResetView