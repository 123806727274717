import {FC} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../store'
import {Grid} from "@mantine/core";
import PageScrollPanel from "../panels/PageScrollPanel";
import ClientPage from "../panels/ClientPage";

const ClientListView: FC = () => {
  const clientIds = useSelector<RootState, number[]>(state => state.application.searchResult.clientIds)
  return (
      <Grid p="sm" w="100%">
        <PageScrollPanel ids={clientIds} pageSize={Number(process.env.REACT_APP_PAGE_SIZE)} component={ids => <ClientPage key={ids} ids={ids}/>}/>
      </Grid>
  )
}

export default ClientListView