import {ChangeEvent, FC} from 'react'
import {Checkbox, ScrollArea, Stack} from '@mantine/core'

interface MultiselectAlternateEntryProps {
  value: string[],
  onChange: (value: string[]) => void,
  data: string[],
}

const MultiselectAlternateEntry: FC<MultiselectAlternateEntryProps> = props => {
  const {value, onChange, data} = props
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    console.log(`Name: ${name}`)
    if (value.includes(name)){
      onChange(value.filter(it => it !== name))
    } else {
      onChange([...value, name])
    }
  }
  return (
    <ScrollArea h={160}>
      <Stack>
        {data.map(it => (
          <Checkbox key={it} name={it} label={it} checked={value.includes(it)} onChange={handleChange}/>
        ))}
      </Stack>
    </ScrollArea>
  )
}

export default MultiselectAlternateEntry