import {FC} from 'react'
import {Text} from '@mantine/core'
import YAML from 'yaml'

interface DataPanelProps {
  data: any,
}

const DataPanel: FC<DataPanelProps> = props => {
  const {data} = props
  return (
      <Text fz="xs">{YAML.stringify(data, null, 2)}</Text>
  )
}

export default DataPanel