import {FC} from 'react'
import {Button, Group, Stack, Text, TextInput} from '@mantine/core'
import {defaultPasswordReset, PasswordReset} from '../entities/PasswordReset'
import {useForm} from '@mantine/form'
import {useTranslation} from "react-i18next";

interface PasswordResetFormProps {
  onOk: (passwordReset: PasswordReset) => void,
  onCancel: () => void,
}

const PasswordResetForm: FC<PasswordResetFormProps> = props => {
  const {onOk, onCancel} = props
  const {t} = useTranslation()
  const form = useForm<PasswordReset>({
    initialValues: defaultPasswordReset,
    validate: {
      email: value => value.length === 0 ? 'Mandatory field' : null,
    }
  })
  return (
    <form noValidate onSubmit={form.onSubmit((values) => onOk(values))}>
      <Stack align="center">
        <TextInput required label="Email" {...form.getInputProps('email')} w={400}/>
        <Text fz="xs">{t('help_reset_password')}</Text>
        <Group>
          <Button w={100} type="submit">OK</Button>
          <Button w={100} variant="outline" onClick={onCancel}>Cancel</Button>
        </Group>
      </Stack>
    </form>
  )
}

export default PasswordResetForm