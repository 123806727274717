import remote from "./remote"
import {state} from './store'
import {Feedback} from './entities/Feedback'
import {Perpetual} from './entities/Perpetual'
import {SearchResult} from './entities/SearchResult'
import {Marker} from './entities/Marker'
import {Invite} from './entities/Invite'

// const delay = async (ms: number) => new Promise<void>(resolve => setTimeout(resolve, ms))

async function wrap<T>(fn: () => Promise<T>): Promise<T> {
  state.updateFetching(true)
  try {
    return await fn()
  } catch (e: any) {
    state.notify({level: 'error', message: e.message}, 5000)
    throw e
  } finally {
    state.updateFetching(false)
  }
}

class Api {
  private api = `${process.env.REACT_APP_REMOTE_URL}/api`
  private auth = `${process.env.REACT_APP_REMOTE_URL}/auth`

  async sendPasswordResetEmail(email: string){ return remote.post<Feedback>(`${this.auth}/reset-password`, {email}) }
  async changePassword(token: string, current: string, password: string){ return remote.post<Feedback>(`${this.auth}/change-password`, {token, current, password}) }
  async perpetual() { state.updatePerpetual(await remote.get<Perpetual>(`${this.api}/perpetual`)) }
  async error() { await remote.get<Perpetual>(`${this.api}/error`) }
  async search(query: any) { return wrap(() => remote.post<SearchResult>(`${this.api}/search`, query)) }
  async checkUniqueness(type: string, value: string) { return remote.post<number>(`${this.api}/check-uniqueness/${type}`, value)}
  // async clients(query: any) { return wrap(() => remote.post<number[]>(`${API_PREFIX}/clients`, query)) }

  async ids(entity: string, query: any){return wrap(() => remote.post<number[]>(`${this.api}/ids/${entity}`, query))}
  async entities<T>(entity: string, ids: number[]) { return wrap(() => remote.post<T[]>(`${this.api}/entities/${entity}`, ids)) }
  async deleteIds(entity: string, ids: number[]){ return wrap(() => remote.delete<boolean>(`${this.api}/${entity}`, ids)) }
  async save<T>(entity: string, t: T) { return wrap(() => remote.post<T>(`${this.api}/${entity}`, t)) }
  async invite(invite: Invite){ return wrap(() => remote.post<void>(`${this.api}/invite`, invite)) }
  async markers(type: string){ return remote.get<Marker[]>(`${this.api}/markers/${type}`)}
}

export default new Api()
