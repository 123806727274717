import {FC, Fragment, ReactNode, useEffect, useRef, useState} from 'react'
import {Button, Divider} from '@mantine/core'
import {useTranslation} from 'react-i18next'

interface PageScrollPanelProps {
  ids: number[],
  pageSize: number,
  component: (ids: string) => ReactNode,
}

const PageScrollPanel: FC<PageScrollPanelProps> = props => {
  console.log(`Page scroll panel re-renders`)
  const {ids, pageSize, component} = props
  const {t} = useTranslation()
  const [pages, setPages] = useState<number[]>([0])
  const totalPageCount = Math.floor(ids.length / pageSize) + (ids.length % pageSize === 0 ? 0 : 1)
  const ref = useRef<HTMLButtonElement>(null)
  useEffect(() => {
    const handleScroll = () => {
      if (ref.current && pages.length < totalPageCount){
        const rect = ref.current.getBoundingClientRect()
        const visible = rect.top >= 0 && rect.bottom < (window.innerHeight || document.documentElement.clientHeight)
        console.log(`Visible: ${visible}`)
        if (visible){
          setPages([...pages, pages.length])
        }
      }
    }
    window.addEventListener('scroll', handleScroll, true)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [ids, pageSize, pages, totalPageCount])
  return (
    <Fragment>
      {pages.map(it => component(ids.slice(it * pageSize, (it + 1) * pageSize).join(':')))}
      {pages.length < totalPageCount &&
          <Fragment>
            <Divider/>
            <Button w="100%" ref={ref} onClick={() => setPages([...pages, pages.length])}>{t('more')}</Button>
          </Fragment>
      }
    </Fragment>
  )
}

export default PageScrollPanel