import {FC} from 'react'
import {Button, Checkbox, Group, Stack} from '@mantine/core'
import {useForm} from '@mantine/form'
import {User, defaultUser} from '../entities/User'

interface UserFormProps {
  user?: User,
  onOk: (user: User) => void,
  onCancel: () => void,
}

const UserForm: FC<UserFormProps> = props => {
  const {user, onOk, onCancel} = props
  const form = useForm<User>({
    initialValues: user || defaultUser,
    validate: {
    }
  })
  return (
    <form noValidate onSubmit={form.onSubmit((values) => onOk(values))}>
      <Stack>
        <Checkbox label="Disabled" {...form.getInputProps('disabled', { type: 'checkbox' })}/>
        <Group>
          <Button w={100} type="submit">OK</Button>
          <Button w={100} variant="outline" onClick={onCancel}>Cancel</Button>
        </Group>
      </Stack>
    </form>
  )
}

export default UserForm