export interface Gate {
  id: number,
  clientId: number,
  accountId: number,
  lastUserId: number,
  disabled: boolean,
  twoFactorAuthenticationEnabled: boolean,
  roles: string[],
  features: string[],
  aspects: string[],
  clientName: string,
  accountName: string,
  name: string,
  clientAvailableRoles: string[],
  clientAvailableFeatures: string[],
  clientAvailableAspects: string[],
  accessible: boolean,
}

export const defaultGate: Gate = {
  id: 0,
  clientId: 0,
  accountId: 0,
  lastUserId: 0,
  disabled: false,
  twoFactorAuthenticationEnabled: false,
  roles: [],
  features: [],
  aspects: [],
  clientName: '',
  accountName: '',
  name: '',
  clientAvailableRoles: [],
  clientAvailableFeatures: [],
  clientAvailableAspects: [],
  accessible: false,
}
