import {FC, useEffect, useState} from 'react'
import {Button, Checkbox, Group, MultiSelect, Stack, Textarea, TextInput} from '@mantine/core'
import {useForm} from '@mantine/form'
import {defaultInvite, Invite} from '../entities/Invite'
import api from '../api'
import {Option} from '../entities/Option'

interface InviteFormProps {
  onCancel: () => void,
  onOk: (invite: Invite) => void,
}

const InviteForm: FC<InviteFormProps> = props => {
  const {onCancel, onOk} = props
  const [clientOptions, setClientOptions] = useState<Option[]>([])
  const form = useForm<Invite>({
    initialValues: defaultInvite,
    validate:{
      emailList: value => value.length === 0 ? 'Mandatory field' : null,
    }
  })
  useEffect(() => {
    (async () => {
      setClientOptions((await api.markers('client')).map(it => ({value: String(it.id), label: it.label})))
    })()
  }, [])
  return (
    <form noValidate onSubmit={form.onSubmit((values) => onOk(values))}>
      <Stack>
        <MultiSelect label="Clients" {...form.getInputProps('clientIds')} data={clientOptions}/>
        <Textarea label="Emails" description="One email per line (use 'Name <email>' for account 'Name')" rows={3} cols={30} {...form.getInputProps('emailList')}/>
        <Checkbox label="Use full 'Name <email>' as email address" {...form.getInputProps('full', { type: 'checkbox' })}/>
        <Checkbox label="Immediately unlock created gate(s)" {...form.getInputProps('unlock', { type: 'checkbox' })}/>
        <Checkbox label="Send an invitation email" {...form.getInputProps('send', { type: 'checkbox' })}/>
        <TextInput label="Initial password" description="Optional, leave blank if none" {...form.getInputProps('initialPassword')}/>
        <Group>
          <Button w={100} type="submit">OK</Button>
          <Button w={100} variant="outline" onClick={onCancel}>Cancel</Button>
        </Group>
      </Stack>
    </form>
  )
}

export default InviteForm