export interface Account {
  id: 0,
  fullName: string,
  expired: boolean,
  locked: boolean,
  accessible: boolean,
  gateIds: number[],
  userIds: number[],
}

export const defaultAccount: Account = {
  id: 0,
  fullName: '',
  expired: false,
  locked: false,
  accessible: false,
  gateIds: [],
  userIds: [],
}