import {FC} from 'react'
import {Outlet} from 'react-router'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {RootState} from '../store'
import {SearchResult} from '../entities/SearchResult'
import {IconAt, IconCloud, IconDoor, IconSearch, IconUser} from '@tabler/icons-react'
import {Box, Divider, Flex, NavLink, Indicator} from '@mantine/core'

const SearchResultLayout: FC = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const searchResult = useSelector<RootState, SearchResult>(state => state.application.searchResult)
  const handleLeftMenu = (route: string) => {
    navigate(route)
  }
  return (
    <Flex id="SearchResultLayout">
      <Box bg="white" style={{flexGrow: 0}}>
        <NavLink label={t('query')} leftSection={<IconSearch/>} onClick={() => handleLeftMenu('query')}/>
        <Divider mb="sm"/>
        <NavLink label={t('clients')} leftSection={<Indicator size={16} label={searchResult.clientIds.length} inline><IconCloud/></Indicator>} onClick={() => handleLeftMenu('client')}/>
        <NavLink label={t('accounts')} leftSection={<Indicator size={16} label={searchResult.accountIds.length} inline><IconUser/></Indicator>} onClick={() => handleLeftMenu('account')}/>
        <NavLink label={t('users')} leftSection={<Indicator size={16} label={searchResult.userIds.length} inline><IconAt/></Indicator>} onClick={() => handleLeftMenu('user')}/>
        <NavLink label={t('gates')} leftSection={<Indicator size={16} label={searchResult.gateIds.length} inline><IconDoor/></Indicator>} onClick={() => handleLeftMenu('gate')}/>
      </Box>
      <Box p="sm" style={{flexGrow: 1}}>
        <Outlet/>
      </Box>
    </Flex>
  )
}

export default SearchResultLayout