const authentication = () => {
  const key = `auth_${process.env.REACT_APP_CLIENT_ID}`
  let k:{[key: string]: any} = {u:{}, t:{}}
  try {
    k = JSON.parse(sessionStorage.getItem(key) || JSON.stringify(k))
  } catch (e: any){
    console.error(e.message)
  }
  const data = {
    signIn: (t: {[key: string]: string}, u: { [key: string]: any }) => {
      sessionStorage.setItem(key, JSON.stringify({t, u}))
    },
    signOut: () => {
      sessionStorage.removeItem(key)
    },
    email: k.u.email,
    signedIn: k.u.email !== undefined,
    idToken: k.t.id_token,
    accessToken: k.t.access_token,
    refreshToken: k.t.refresh_token,
    roles: k.u.roles ?? [],
    isAdmin: (k.u.roles ?? []).includes('admin'),
    isDeveloper: (k.u.roles ?? []).includes('developer'),
  }
  // console.log(`authentication: ${JSON.stringify(data)}`)
  return data
}

export default authentication