export interface Invite {
  clientIds: string[],
  emailList: string,
  send: boolean,
  unlock: boolean,
  full: boolean,
  initialPassword: string,
}

export const defaultInvite: Invite = {
  clientIds: [],
  emailList: '',
  send: false,
  unlock: false,
  full: false,
  initialPassword: '',
}