import {FC, ReactNode} from 'react'
import {
  Stack,
  Text,
  ActionIcon,
  Paper, Group,
} from '@mantine/core'
import {IconPlus, IconX} from '@tabler/icons-react'

interface StringListInputProps {
  value?: string[],
  onChange?: (value: string[]) => void,
  label?: ReactNode,
  separator?: string,
}

const StringListInput: FC<StringListInputProps> = props => {
  const {value, onChange, separator, label, ...otherProps} = props
  const handleAdd = () => {
    const s = window.prompt("Add")
    if (s && onChange && !(value && value.includes(s.trim()))){
      onChange([s.trim(), ...(value ?? [])])
    }
  }
  const handleRemove = (s: string) => {
    if (onChange && value){
      onChange(value.filter(it => it !== s))
    }
  }
  return (
    <Stack gap={1}>
      <Group gap={1}>
        <Text fz="sm" fw="bold">{label}</Text>
        <ActionIcon variant="transparent" size="xs" onClick={handleAdd}><IconPlus style={{ width: 10, height: 10 }} /></ActionIcon>
      </Group>
      <Group>
        {value && value.sort().map((it, index) => <Paper key={index} radius="md" shadow="sm" pl="sm" withBorder>
          <Group gap={0}>
            <Text fz="xs">{it}</Text>
            <ActionIcon variant="transparent" onClick={() => handleRemove(it)}><IconX style={{ width: 10, height: 10 }}/></ActionIcon>
          </Group>
        </Paper>)}
      </Group>
    </Stack>
  )
}

export default StringListInput
