import {FC, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {Box, Button, Card, Center, Stack, Text, Image, LoadingOverlay} from '@mantine/core'
import authentication from '../authentication'
import Keyhole from '../keyhole.svg'

const PKCE_KEY= 'pkce_challenge'

const hash = async (s: string) => {
  const digest = await window.crypto.subtle.digest('SHA-256', new TextEncoder().encode(s))
  const bytes = new Uint8Array(digest)
  let t = ''
  for (let i = 0; i < bytes.byteLength; i++) t += String.fromCharCode(bytes[i])
  return window.btoa(t)
}

const MainView: FC = () => {
  const {t} = useTranslation()
  const location = window.location
  const uri = `${location.protocol}//${location.host}${process.env.REACT_APP_WEB_CONTEXT}`
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [error, setError] = useState<string|null>(searchParams.get('error'))
  const [signInUrl, setSignInUrl] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  useEffect(() => {
    (async () => {
      const code = searchParams.get('code')
      const {signIn, signedIn} = authentication()
      if (signedIn){
        navigate('/app')
      } else if (code){
        const search = new URLSearchParams()
        search.append('grant_type', 'authorization_code')
        search.append('code', code)
        search.append('redirect_uri', uri)
        search.append('scope', searchParams.get('scope') || '')
        const codeVerifier = sessionStorage.getItem(PKCE_KEY)
        if (codeVerifier){
          search.append('code_verifier', codeVerifier)
        }
        try {
          const res = await fetch(`${process.env.REACT_APP_REMOTE_URL}${process.env.REACT_APP_WEB_CONTEXT}/auth/token`,  {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: search,
          })
          const t = await res.json()
          console.log(`Response: ${JSON.stringify(t)}`)
          const {error, access_token} = t
          if (error){
            setError(error)
          } else {
            const res = await fetch(`${process.env.REACT_APP_REMOTE_URL}${process.env.REACT_APP_WEB_CONTEXT}/auth/userinfo`, {
              headers: {
                'Authorization': `Bearer ${access_token}`
              }
            })
            const u = await res.json()
            console.log(`User info: ${JSON.stringify(u)}`)
            signIn(t, u)
            navigate('/app')
          }
        } catch(e: any){
          setError(e.message)
        }
        navigate('/')
      } else {
        const location = window.location
        const uri = `${location.protocol}//${location.host}${process.env.REACT_APP_WEB_CONTEXT}`
        const search = new URLSearchParams()
        search.append('client_id', process.env.REACT_APP_CLIENT_ID || '?')
        search.append('redirect_uri', uri)
        search.append('scope', 'openid email profile')
        search.append('response_type', 'code')
        let r = (Math.random() + 1).toString(36).substring(2)
        sessionStorage.setItem(PKCE_KEY, r)
        const algorithm = 'sha-256'
        search.append('code_challenge_method', algorithm)
        search.append('code_challenge', await hash(r))
        setSignInUrl(`${process.env.REACT_APP_WEB_CONTEXT}/sign-in?${search}`)
        setLoading(false)
      }
    })()
  }, [navigate, searchParams, uri])
  return (
    <Box h="100vh" pos="relative">
      <LoadingOverlay visible={loading}/>
      <Center h="100%">
        <Card shadow="lg" withBorder>
          <Stack align="center" gap="xs">
            <Image src={Keyhole} maw={50}/>
            <Text size="lg" mb="lg">{t('gate')}</Text>
            {error && <Text mb="lg" c="red">{t(error)}</Text>}
            <Button onClick={() => window.location.replace(signInUrl)}>{t('sign_in')}</Button>
          </Stack>
        </Card>
      </Center>
    </Box>
  )
}

export default MainView
