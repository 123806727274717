import {FC} from 'react'
import {Button, Group, PasswordInput, Stack, TextInput} from '@mantine/core'
import {defaultSignIn, SignIn} from '../entities/SignIn'
import {useForm} from '@mantine/form'

interface SignInFormProps {
  onOk: (signIn: SignIn) => void,
  onCancel: () => void,
}

const SignInForm: FC<SignInFormProps> = props => {
  const {onOk, onCancel} = props
  const form = useForm<SignIn>({
    initialValues: defaultSignIn,
    validate: {
      email: value => value.length === 0 ? 'Mandatory field' : null,
      password: value => value.length === 0 ? 'Mandatory field' : null,
    }
  })
  return (
    <form noValidate onSubmit={form.onSubmit((values) => onOk(values))}>
      <Stack>
        <TextInput required label="Email" {...form.getInputProps('email')}/>
        <PasswordInput required label="Password" {...form.getInputProps('password')}/>
        <Group p="center">
          <Button w={100} type="submit">OK</Button>
          <Button w={100} variant="outline" onClick={onCancel}>Cancel</Button>
        </Group>
      </Stack>
    </form>
  )
}

export default SignInForm