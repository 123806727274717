import {FC} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../store'
import {SearchResult} from '../entities/SearchResult'
import DataPanel from '../panels/DataPanel'
import {Box} from "@mantine/core";

const QueryView: FC = () => {
  const searchResult = useSelector<RootState, SearchResult>(state => state.application.searchResult)
  return (
      <Box bg="white">
        <DataPanel data={searchResult.query}/>
      </Box>
  )
}

export default QueryView