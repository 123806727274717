import {FC} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../store'
import {Feedback} from '../entities/Feedback'
import {Outlet} from 'react-router'
import {Box, Affix, Notification, Loader} from '@mantine/core'

const MainLayout: FC = () => {
  const feedbacks = useSelector<RootState, Feedback[]>(state => state.application.feedbacks)
  const fetching = useSelector<RootState, boolean>(state => state.application.fetching)
  return (
    <Box id="MainLayout">
      {fetching && <Loader type="bars" color="secondary" pos="absolute" top={0} left={0} w="100%" z={2000}/>}
      <Outlet/>
      <Affix position={{bottom: 20, right: 20}}>
        {feedbacks.map((it, i) => {
          switch (it.level){
            case 'error':
              return (<Notification key={i} color="red" title="Error">{it.message}</Notification>)
            case 'success':
              return (<Notification key={i} color="green" title="Success">{it.message}</Notification>)
            case 'warning':
              return (<Notification key={i} color="yellow" title="Warning">{it.message}</Notification>)
            case 'info':
              return (<Notification key={i} color="blue" title="Information">{it.message}</Notification>)
            default:
              return (<Notification key={i}>{it.message}</Notification>)
          }
        })}
      </Affix>
    </Box>
  )
}

export default MainLayout