import {FC} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../store'
import {Grid} from "@mantine/core";
import PageScrollPanel from "../panels/PageScrollPanel";
import UserPage from "../panels/UserPage";

const UserListView: FC = () => {
  const userIds = useSelector<RootState, number[]>(state => state.application.searchResult.userIds)
  return (
      <Grid p="sm" w="100%">
        <PageScrollPanel ids={userIds} pageSize={Number(process.env.REACT_APP_PAGE_SIZE)} component={ids => <UserPage key={ids} ids={ids}/>}/>
      </Grid>
  )
}

export default UserListView