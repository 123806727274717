import {FC} from 'react'
import ReactMarkdown from 'react-markdown'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {RootState} from '../store'
import {Box, Center, Card, Button, Group, Image} from '@mantine/core'
import Keyhole from '../keyhole.svg'

const EndView: FC = () => {
  const {t} = useTranslation()
  const location = useLocation()
  const redirect = useSelector<RootState, string>(state => state.application.redirect)
  return (
    <Box h="100vh">
      <Center h="100%">
        <Card shadow="lg">
          <Center>
            <Image src={Keyhole} maw={30}/>
          </Center>
          <Group p="center" c="red">
            <ReactMarkdown>{location.state.error}</ReactMarkdown>
          </Group>
          <Group p="center" c="blue">
            <ReactMarkdown>{location.state.info}</ReactMarkdown>
          </Group>
          <Group p="center" c="black" bg="yellow">
            <ReactMarkdown>{location.state.warning}</ReactMarkdown>
          </Group>
          <Group p="center" c="green">
            <ReactMarkdown>{location.state.success}</ReactMarkdown>
          </Group>
          {redirect.length > 0 &&
              <Center>
                  <Button onClick={() => window.location.href = redirect} fullWidth>{t('redirect')}</Button>
              </Center>
          }
        </Card>
      </Center>
    </Box>
  )
}

export default EndView