import {Outlet, useNavigate} from 'react-router'
import {FC, useEffect, useState} from 'react'
import {state} from '../store'
import {useTranslation} from 'react-i18next'
import api from '../api'
import {IconHome, IconPlus, IconUser} from '@tabler/icons-react'
import {ActionIcon, AppShell, Flex, Group, Menu, Modal, useMantineTheme} from '@mantine/core'
import SearchForm from '../forms/SearchForm'
import {Search} from '../entities/Search'
import {Client, defaultClient} from '../entities/Client'
import ClientForm from '../forms/ClientForm'
import {defaultSearchResult} from '../entities/SearchResult'
import {defaultInvite, Invite} from '../entities/Invite'
import InviteForm from '../forms/InviteForm'
import authentication from '../authentication'

const AppLayout: FC = () => {
  const theme = useMantineTheme()
  const {t} = useTranslation()
  const {signOut} = authentication()
  const navigate = useNavigate()
  const [client, setClient] = useState<Client|undefined>()
  const [invite, setInvite] = useState<Invite|undefined>()
  const handleSearch = async (search: Search) => {
    console.log(`Searching for: ${JSON.stringify(search)}`)
    try {
      state.updateSearchResult(await api.search({term: search.term}))
      navigate('search-result')
    } catch (e: any) {
      state.notifyError(e.message)
    }
  }
  const handleOkClient = async (client: Client) => {
    try {
      const saved = await api.save<Client>('client', client)
      state.updateSearchResult({...defaultSearchResult, clientIds: [saved.id]})
      navigate('/app/search-result/client')
      setClient(undefined)
    } catch (e: any) {
      state.notifyError(e.message)
    }
  }
  const handleOkInvite = async (invite: Invite) => {
    try{
      await api.invite(invite)
      setInvite(undefined)
    } catch (e: any) {
      state.notifyError(e.message)
    }
  }
  const handleSignOut = () => {
    if (window.confirm(t('do_you_want_to_sign_out'))) {
      signOut()
      navigate('/')
    }
  }
/*
  const handleError = async () => {
    try {
      await api.error()
    } catch(e: any){
      console.log(typeof e)
      console.log(Object.keys(e))
      console.error(e)
      state.notifyError(e.message)
    }
  }
*/
  useEffect(() => {
    (async () => {
      try {
        await api.perpetual()
      } catch (e: any) {
        navigate('/', {state:{error: 'error_token_expired'}})
        state.notifyError(e.message)
      }
    })()
  }, [navigate])
  return (
    <AppShell header={{height: 45}}>
      <AppShell.Header>
        <Flex h="100%" direction="column" justify="center" bg={theme.primaryColor}>
          <Group justify="space-between" p="sm">
            <ActionIcon onClick={() => navigate('')}>
              <IconHome/>
            </ActionIcon>
            <SearchForm onOk={handleSearch}/>
            <Group>
              <Menu shadow="md">
                <Menu.Target>
                  <ActionIcon>
                    <IconPlus/>
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item onClick={() => setClient(defaultClient)}>{t('add_client')}...</Menu.Item>
                  <Menu.Divider/>
                  <Menu.Item onClick={() => setInvite(defaultInvite)}>{t('invite_users')}...</Menu.Item>
                </Menu.Dropdown>
              </Menu>
              <Menu shadow="md">
                <Menu.Target>
                  <ActionIcon>
                    <IconUser/>
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item onClick={() => navigate(`/password-change?r=${process.env.PUBLIC_URL}&q=true`)}>{t('change_password')}</Menu.Item>
                  <Menu.Divider/>
                  <Menu.Item onClick={handleSignOut}>{t('sign_out')}</Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Group>
          </Group>
        </Flex>
      </AppShell.Header>
      <AppShell.Main>
        <Outlet/>
      </AppShell.Main>
      <Modal opened={Boolean(client)} onClose={() => setClient(undefined)} title={`New client`}>
        <ClientForm client={client} onCancel={() => setClient(undefined)} onOk={handleOkClient}/>
      </Modal>
      <Modal opened={Boolean(invite)} onClose={() => setInvite(undefined)} title={`Invite users`}>
        <InviteForm onCancel={() => setInvite(undefined)} onOk={handleOkInvite}/>
      </Modal>
    </AppShell>
  )
}

export default AppLayout
