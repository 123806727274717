import {FC, Fragment, useEffect, useState} from "react";
import {Account} from "../entities/Account";
import api from "../api";
import {state} from "../store";
import {ActionIcon, Button, Card, Grid, Group, Indicator, Modal} from "@mantine/core";
import {IconAt, IconDoor, IconLock, IconTrash} from "@tabler/icons-react";
import AccountForm from "../forms/AccountForm";
import {defaultSearchResult} from "../entities/SearchResult";
import {useNavigate} from "react-router-dom";

const AccountPage: FC<{ids: string}> = props => {
    const {ids} = props
    const idsAsNumberArray = ids.split(':').map(it => Number(it))
    const navigate = useNavigate()
    const [accounts, setAccounts] = useState<Account[]>([])
    const [edit, setEdit] = useState<Account|undefined>()
    const handleOk = async (account: Account) => {
        try {
            const savedAccount = await api.save<Account>('account', account)
            setAccounts(accounts.map(it => savedAccount.id === it.id ? savedAccount : it))
        } catch (e: any) {
            state.notifyError(e.message)
        }
        setEdit(undefined)
    }
    const handleDelete = async (account: Account) => {
        if (window.confirm(`Are you sure to delete account: ${account.fullName} ?`)){
            try {
                if (await api.deleteIds('account', [account.id])){
                    setAccounts(accounts.filter(it => it.id !== account.id))
                }
            } catch (e: any) {
                state.notifyError(e.message)
            }
        }
    }
    const handleGotoGates = (account: Account) => {
        state.updateSearchResult({...defaultSearchResult, gateIds: account.gateIds})
        navigate('/app/search-result/gate')
    }
    const handleGotoUsers = (account: Account) => {
        state.updateSearchResult({...defaultSearchResult, userIds: account.userIds})
        navigate('/app/search-result/user')
    }

    useEffect(() => {
        (async () => {
            try {
                setAccounts((await api.entities<Account>('account', idsAsNumberArray)).sort((it1, it2) => idsAsNumberArray.indexOf(it1.id) - idsAsNumberArray.indexOf(it2.id)))
            } catch (e: any){
                state.notifyError(e.message)
            }
        })()
    }, [ids]);
    return (
        <Fragment>
            {accounts.map(it => <Grid.Col key={it.id} span={{ base: 12, md: 6, lg: 3 }}>
                <Card shadow="md">
                    <Card.Section>
                        <Group justify="space-between" w="100%">
                            <Button variant="transparent" onClick={() => setEdit(it)}>{it.fullName}</Button>
                            {!it.accessible && <IconLock size={16} color="red"/>}
                        </Group>
                    </Card.Section>
                    <Card.Section>
                        <Group justify="space-between" w="100%">
                            <Group gap={2}>
                                <ActionIcon variant="transparent" onClick={() => handleGotoGates(it)}>
                                    <Indicator size={16} label={String(it.gateIds.length)}>
                                        <IconDoor size={16}/>
                                    </Indicator>
                                </ActionIcon>
                                <ActionIcon variant="transparent" onClick={() => handleGotoUsers(it)}>
                                    <Indicator size={16} label={String(it.userIds.length)}>
                                        <IconAt size={16}/>
                                    </Indicator>
                                </ActionIcon>
                            </Group>
                            {it.gateIds.length === 0 && it.userIds.length === 0 && <ActionIcon variant="transparent" onClick={() => handleDelete(it)}>
                                <IconTrash size={16}/>
                            </ActionIcon>}
                        </Group>
                    </Card.Section>
                </Card>
            </Grid.Col> )}
            <Modal opened={Boolean(edit)} onClose={() => setEdit(undefined)} title={`Account: ${edit && edit.fullName}`}>
                <AccountForm account={edit} onCancel={() => setEdit(undefined)} onOk={handleOk}/>
            </Modal>
        </Fragment>
    )
}

export default AccountPage
