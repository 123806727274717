import {FC, useEffect, useState} from 'react'
import {Button, Checkbox, Group, MultiSelect, Stack} from '@mantine/core'
import {useForm} from '@mantine/form'
import {defaultGate, Gate} from '../entities/Gate'
import MarkerEntry from '../components/MarkerEntry'
import {Client, defaultClient} from '../entities/Client'
import api from '../api'

interface GateFormProps {
  gate?: Gate,
  onOk: (gate: Gate) => void,
  onCancel: () => void,
}

const GateForm: FC<GateFormProps> = props => {
  const {gate, onOk, onCancel} = props
  const [client, setClient] = useState<Client>(defaultClient)
  const form = useForm<Gate>({
    initialValues: gate || defaultGate,
    validate: {
      accountId: value => value === 0 ? 'Mandatory field' : null,
      clientId: value => value === 0 ? 'Mandatory field' : null,
    }
  })
  const fetchClient = (id: number) => {
    (async () => {
      try {
        const clients = await api.entities<Client>('client', [id])
        setClient(clients.length > 0 ? clients[0] : defaultClient)
      } catch (e: any) {
        console.error(e.message)
      }
    })()
  }
  const handleChangeClient = (clientId: number) => {
    form.setValues({...form.values, clientId, roles: [], features: [], aspects: []})
    fetchClient(clientId)
  }
  useEffect(() => {
    fetchClient(gate?.clientId ?? 0)
  }, [gate])
  return (
    <form noValidate onSubmit={form.onSubmit((values) => onOk(values))}>
      <Stack>
        <Group>
          <Checkbox label="Disabled" {...form.getInputProps('disabled', {type: 'checkbox'})}/>
          <Checkbox label="2FA" {...form.getInputProps('twoFactorAuthenticationEnabled', {type: 'checkbox'})}/>
        </Group>
        <MarkerEntry required label="Account" type="account" {...form.getInputProps('accountId')}/>
        <MarkerEntry required label="Client" type="client" value={form.getInputProps('clientId').value}
                     onChange={handleChangeClient} error={form.getInputProps('clientId').error}/>
        <MultiSelect label="Roles" value={form.values.roles} onChange={roles => form.setValues({...form.values, roles})}
                     data={client.availableRoles}/>
        <MultiSelect label="Features" value={form.values.features}
                     onChange={features => form.setValues({...form.values, features})}
                     data={client.availableFeatures}/>
        <MultiSelect label="Aspects" value={form.values.aspects}
                     onChange={aspects => form.setValues({...form.values, aspects})}
                     data={client.availableAspects}/>
        <Group>
          <Button w={100} type="submit">OK</Button>
          <Button w={100} variant="outline" onClick={onCancel}>Cancel</Button>
        </Group>
      </Stack>
    </form>
  )
}

export default GateForm
