import {Select, SelectProps} from '@mantine/core'
import {FC} from 'react'

interface OptionalSelectProps extends Omit<SelectProps, 'value'|'onChange'> {
  value: string|null,
  onChange: (value: string|null) => void,
}

const OptionalSelect: FC<OptionalSelectProps> = props => {
  const {value, onChange, ...otherProps} = props
  const handleChange = (v: string|null) => {
    onChange(v === '' ? null: v)
  }
  return <Select value={value ?? ''} onChange={handleChange} {...otherProps}/>
}

export default OptionalSelect
