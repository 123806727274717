import {FC, SetStateAction, useState} from 'react'
import {Box, Button} from '@mantine/core'
import {defaultGate, Gate} from '../entities/Gate'
import GateForm from '../forms/GateForm'
import {state} from '../store'
import MultiselectAlternateEntry from '../components/MultiselectAlternateEntry'
import StringListInput from '../components/StringListInput'

const DATA = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']

const TestView: FC = () => {
  console.log('re-render test')
  const [gate, setGate] = useState<Gate>(defaultGate)
  const handleGate = (gate: Gate) => {
    console.log(JSON.stringify(gate))
    setGate(gate)
  }
  const [strings, setStrings] = useState<string[]>([])
  return (
    <Box>
      <GateForm gate={gate} onOk={handleGate} onCancel={() => {
      }}/>
      <br/>
      <Button onClick={() => state.notifyWarning('Some warning')}>Warning</Button>
      <br/>
      <StringListInput value={strings} onChange={value => setStrings(value)}/>
      <br/>
      <MultiselectAlternateEntry value={strings} onChange={(value: SetStateAction<string[]>) => setStrings(value)} data={DATA}/>
    </Box>
  )
}

export default TestView
