import {FC} from 'react'
import {useTranslation} from "react-i18next"
import {useNavigate, useSearchParams} from 'react-router-dom'
import SignInForm from '../forms/SignInForm'
import {Button, Card, Text, Image, Stack, Flex} from '@mantine/core'
import {SignIn} from '../entities/SignIn'
import Keyhole from '../keyhole.svg'

const SignInView: FC = () => {
  const [searchParams] = useSearchParams()
  const {t} = useTranslation()
  const navigate = useNavigate()
  const handleSignIn = async (signIn: SignIn) => {
    const search = new URLSearchParams()
    try {
      searchParams.set('email', signIn.email)
      searchParams.set('password', signIn.password)
      let res = await fetch(`${process.env.REACT_APP_REMOTE_URL}${process.env.REACT_APP_WEB_CONTEXT}/auth/authorize?${searchParams}`)
      const authorization = await res.json()  // either code, two_factor_authentication_method or error
      console.log(`authorization response: ${authorization}`)
      if ('code' in authorization){  // direct
        search.append('code', authorization['code']);
        ['state', 'forward'].filter(it => searchParams.has(it)).forEach(it => search.append(it, searchParams.get(it) ?? ''))
        window.location.replace(`${searchParams.get('redirect_uri')}?${search}`)
      } else if ('two_factor_authentication_method' in authorization){  // two factor
        navigate('/passcode-confirm', { state: {searchParamsAsQueryString: searchParams.toString(), email: signIn.email, password: signIn.password} })
      } else {
        search.append('error', t(authorization.error_description || authorization.error || 'error_undefined'))
        window.location.replace(`${searchParams.get('redirect_uri')}?${search}`)
      }
    } catch (e: any) {
      console.log(`Error signing in: ${e.message}`)
      search.append('error', t(e.message))
    }
  }
  const handleCancel = () => {
    const uri = `${searchParams.get('redirect_uri')}?error=${t('sign_in_cancelled')}`
    window.location.replace(uri)
  }
  return (
    <Flex h="100vh" justify="center" align="center">
      <Card shadow="lg" withBorder>
        <Stack align="center">
          <Image src={Keyhole} maw={30}/>
          <Text size="lg">{t('sign_in')}</Text>
          <SignInForm onOk={handleSignIn} onCancel={handleCancel}/>
          <Button variant="subtle" onClick={() => navigate('/password-reset')}>{t('forgot_password')}?</Button>
        </Stack>
      </Card>
    </Flex>
  )
}

export default SignInView
