export interface SearchResult {
  query: any,
  clientIds: number[],
  accountIds: number[],
  userIds: number[],
  gateIds: number[],
}

export const defaultSearchResult: SearchResult = {
  query: '',
  clientIds: [],
  accountIds: [],
  userIds: [],
  gateIds: [],
}

