export interface User {
  id: number,
  email: string,
  pass: string,
  disabled: boolean,
  credentialsExpired: boolean,
  emailVerified: boolean,
  accessible: boolean,
  accountId: number,
}

export const defaultUser: User = {
  id: 0,
  email: '',
  pass: '',
  disabled: false,
  credentialsExpired: false,
  emailVerified: false,
  accessible: false,
  accountId: 0,
}