import {FC} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../store'
import {Grid} from "@mantine/core";
import PageScrollPanel from "../panels/PageScrollPanel";
import GatePage from "../panels/GatePage";

const GateListView: FC = () => {
  const gateIds = useSelector<RootState, number[]>(state => state.application.searchResult.gateIds)
  return (
      <Grid p="sm" w="100%">
        <PageScrollPanel ids={gateIds} pageSize={Number(process.env.REACT_APP_PAGE_SIZE)} component={ids => <GatePage key={ids} ids={ids}/>}/>
      </Grid>
  )
}

export default GateListView