import {FC} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../store'
import PageScrollPanel from "../panels/PageScrollPanel";
import AccountPage from "../panels/AccountPage";
import {Grid} from "@mantine/core";

const AccountListView: FC = () => {
  const accountIds = useSelector<RootState, number[]>(state => state.application.searchResult.accountIds)
  return (
      <Grid p="sm" w="100%">
        <PageScrollPanel ids={accountIds} pageSize={Number(process.env.REACT_APP_PAGE_SIZE)} component={ids => <AccountPage key={ids} ids={ids}/>}/>
      </Grid>
  )
}

export default AccountListView