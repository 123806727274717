import {FC} from 'react'
import {Card, Flex, Image, Stack, Text} from '@mantine/core'
import Keyhole from '../keyhole.svg'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'
import PasscodeConfirmForm from '../forms/PasscodeConfirmForm'
import {PasscodeConfirm} from '../entities/PasscodeConfirm'
import {state as applicationState} from '../store'

const PasscodeConfirmView: FC = () => {
  const {t} = useTranslation()
  const {state} = useLocation()
  const {searchParamsAsQueryString, email, password} = state
  const searchParams = new URLSearchParams(searchParamsAsQueryString)
  const handleConfirm = async (passcodeConfirm: PasscodeConfirm) => {
    const search = new URLSearchParams()
    try {
      searchParams.set('email', email)
      searchParams.set('password', password)
      searchParams.set('passcode', passcodeConfirm.code)
      let res = await fetch(`${process.env.REACT_APP_REMOTE_URL}${process.env.REACT_APP_WEB_CONTEXT}/auth/authorize?${searchParams}`)
      const authorization = await res.json()  // either code, two_factor_authentication_method or error
      console.log(`confirm response: ${JSON.stringify(authorization)}`)
      if ('code' in authorization){  // direct
        search.append('code', authorization['code']);
        ['state', 'forward'].filter(it => searchParams.has(it)).forEach(it => search.append(it, searchParams.get(it) ?? ''))
        window.location.replace(`${searchParams.get('redirect_uri')}?${search}`)
      } else {
        search.append('error', t(authorization.error_description || authorization.error || 'error_undefined'))
        window.location.replace(`${searchParams.get('redirect_uri')}?${search}`)
      }
    } catch (e: any) {
      applicationState.notifyError(t(e.message))
    }
  }
  const handleCancel = () => {
    const uri = `${searchParams.get('redirect_uri')}?error=${t('sign_in_cancelled')}`
    window.location.replace(uri)
  }
  return (
    <Flex h="100vh" justify="center" align="center">
      <Card shadow="lg" withBorder>
        <Stack align="center">
          <Image src={Keyhole} maw={30}/>
          <Text size="lg">{t('two_factor_authentication')}</Text>
{/*
          <Text size="xs">{JSON.stringify(searchParamsAsQueryString)}</Text>
          <Text size="xs">{email}</Text>
*/}
          <PasscodeConfirmForm onOk={handleConfirm} onCancel={handleCancel}/>
        </Stack>
      </Card>
    </Flex>
  )
}

export default PasscodeConfirmView
