import {FC} from 'react'
import {Button, Group, PasswordInput, Stack} from '@mantine/core'
import {useForm} from '@mantine/form'
import {defaultPasswordChange, PasswordChange} from '../entities/PasswordChange'

interface PasswordChangeFormProps {
  requestCurrent: boolean,
  onOk: (passwordChange: PasswordChange) => void,
  onCancel: () => void,
}

const PasswordChangeForm: FC<PasswordChangeFormProps> = props => {
  const {requestCurrent, onOk, onCancel} = props
  const form = useForm<PasswordChange>({
    initialValues: defaultPasswordChange,
    validate: {
      update: value => value.length === 0 ? 'Mandatory field' : null,
      confirm: (value, values) => value !== values.update ? 'Confirm does not match' : null,
    }
  })
  return (
    <form noValidate onSubmit={form.onSubmit((values) => onOk(values))}>
      <Stack p="center">
        {requestCurrent &&
            <PasswordInput required label="Current password" {...form.getInputProps('current')} w={400}/>
        }
        <PasswordInput required label="New password" {...form.getInputProps('update')} w={400}/>
        <PasswordInput required label="Confirm new password" {...form.getInputProps('confirm')} w={400}/>
        <Group>
          <Button w={100} type="submit">OK</Button>
          <Button w={100} variant="outline" onClick={onCancel}>Cancel</Button>
        </Group>
      </Stack>
    </form>
  )
}

export default PasswordChangeForm