import {FC, useEffect, useState} from 'react'
import {Autocomplete, InputBaseProps} from '@mantine/core'
import {defaultMarker, Marker} from '../entities/Marker'
import api from '../api'
import {state} from '../store'

interface MarkerEntryProps extends InputBaseProps {
  value: number,
  onChange: (value: number) => void,
  type: string,
}

/*
interface MarkerProps extends ComboboxProps {
  id: number,
  label: string,
}
*/

const MarkerEntry: FC<MarkerEntryProps> = props => {
  const {value, onChange, type, ...otherProps} = props
  const [text, setText] = useState<string>('')
  const [markers, setMarkers] = useState<Marker[]>([])
/*
  const AutocompleteItem = forwardRef<HTMLDivElement, MarkerProps>(({id, label, ...otherProps}, ref) => (
    <div ref={ref} {...otherProps}>
      <Text>{label}</Text>
      <Text fs="sm" c="dimmed">{id}</Text>
    </div>
  ))
*/
  const handleChange = (value: string) => {
    setText(value)
    const marker = markers.find(it => it.label === value) || defaultMarker
    onChange(marker.id)
  }
  useEffect(() => {
    (async () => {
      try{
        const markers = await api.markers(type)
        setMarkers(markers)
        setText((markers.find(it => it.id === value) || defaultMarker).label)
      } catch (e: any) {
        state.notifyError(e.message)
      }
    })()
  }, [value, type])

  return (
    <Autocomplete {...otherProps}
                  data={markers.map(it => ({...it, value: it.label}))}
                  // itemComponent={AutocompleteItem}
                  // filter={(value, item) => item.label.toLowerCase().includes(value.toLowerCase())}
                  value={text}
                  onChange={handleChange}
    />
  )
}

export default MarkerEntry