import {FC} from 'react'
import {Button, Checkbox, Group, NumberInput, Stack, Tabs, Textarea, TextInput} from '@mantine/core'
import {useForm} from '@mantine/form'
import {Client, defaultClient} from '../entities/Client'
import {useSelector} from "react-redux";
import {Perpetual} from "../entities/Perpetual";
import {RootState} from "../store";
import OptionalSelect from '../components/OptionalSelect'
import StringListInput from '../components/StringListInput'

interface ClientFormProps {
  client?: Client,
  onOk: (client: Client) => void,
  onCancel: () => void,
}


const ClientForm: FC<ClientFormProps> = props => {
  const {client, onOk, onCancel} = props
  const perpetual = useSelector<RootState, Perpetual>(state => state.application.perpetual)
  const validateMandatory = (value: string) => value.length === 0 ? 'Mandatory field' : null
  const form = useForm<Client>({
    initialValues: client || defaultClient,
    validate: {
      identifier: validateMandatory,
      fullName: validateMandatory,
    },
  })
  return (
    <form noValidate onSubmit={form.onSubmit((values) => onOk(values))}>
      <Tabs defaultValue="general" mb="sm">
        <Tabs.List>
          <Tabs.Tab value="general">General</Tabs.Tab>
          <Tabs.Tab value="permissions">Permissions</Tabs.Tab>
          <Tabs.Tab value="redirections">Redirections</Tabs.Tab>
          <Tabs.Tab value="lifetimes">Lifetimes</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="general">
          <Stack>
            <TextInput required label="Identifier" {...form.getInputProps('identifier')}/>
            <TextInput required label="Full name" {...form.getInputProps('fullName')}/>
            <Group>
              <Checkbox label="Public" {...form.getInputProps('public', {type: 'checkbox'})}/>
              <Checkbox label="Native" {...form.getInputProps('native', {type: 'checkbox'})}/>
              <Checkbox label="Enforce PKCE" {...form.getInputProps('enforceProofKeyOfCodeExchange', {type: 'checkbox'})}/>
            </Group>
            <OptionalSelect label="Two-factor authentication" {...form.getInputProps('twoFactorAuthentication')} data={['', ...perpetual.twoFactorAuthentications]}/>
          </Stack>
        </Tabs.Panel>
        <Tabs.Panel value="permissions">
          <Stack>
            <StringListInput label="Available roles" {...form.getInputProps('availableRoles')}/>
            <StringListInput label="Available features" {...form.getInputProps('availableFeatures')}/>
            <StringListInput label="Available aspects" {...form.getInputProps('availableAspects')}/>
          </Stack>
        </Tabs.Panel>
        <Tabs.Panel value="redirections">
          <Stack>
            <Textarea label="Allowed redirect URIs for delivering tokens, one per line." w="100%" minRows={8} {...form.getInputProps('allowedRedirectUris')}/>
          </Stack>
        </Tabs.Panel>
        <Tabs.Panel value="lifetimes">
          <Stack>
            <NumberInput
              label="Authorization Code Lifetime (s)" {...form.getInputProps('authorizationCodeLifetimeInSeconds')}/>
            <NumberInput label="Access Token Lifetime (s)" {...form.getInputProps('accessTokenLifetimeInSeconds')}/>
            <NumberInput label="Refresh Token Lifetime (s)" {...form.getInputProps('refreshTokenLifetimeInSeconds')}/>
            <NumberInput label="ID Token Lifetime (s)" {...form.getInputProps('idTokenLifetimeInSeconds')}/>
          </Stack>
        </Tabs.Panel>
      </Tabs>
      <Group>
        <Button w={100} type="submit">OK</Button>
        <Button w={100} variant="outline" onClick={onCancel}>Cancel</Button>
      </Group>
    </form>
  )
}

export default ClientForm
