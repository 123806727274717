import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'
import Backend from 'i18next-http-backend'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    // resources: translations as any,
    keySeparator: false, // we do not use keys in form messages.welcome
    backend: {
      loadPath: `${process.env.REACT_APP_WEB_CONTEXT}/locales/{{lng}}/{{ns}}.json`,
      crossDomain: false
    },
    interpolation: {
      format: (value: any, fmt?: string, lng?: string) => {
        if (value instanceof Date){
          return new Intl.DateTimeFormat(undefined, {
            weekday: 'long',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
          }).format(value);
        }
        return value.toString();
      }
    }
  }).then(() => console.log('i18n initialized'))

export default i18n

