import {ActionIcon, TextInput} from '@mantine/core'
import {FC} from 'react'
import {IconSearch} from '@tabler/icons-react'
import {useForm} from '@mantine/form'
import {defaultSearch, Search} from '../entities/Search'

interface SearchFormProps {
  onOk: (search: Search) => void,
}

const SearchForm: FC<SearchFormProps> = props => {
  const {onOk} = props
  const form = useForm<Search>({
    initialValues: defaultSearch,
  })
  return (
    <form noValidate onSubmit={form.onSubmit((values) => {form.setValues(defaultSearch); onOk(values)})}>
      <TextInput {...form.getInputProps('term')} rightSection={<ActionIcon type="submit"><IconSearch/></ActionIcon>}/>
    </form>
  )
}

export default SearchForm