export interface PasswordChange {
  current: string,
  update: string,
  confirm: string,
}

export const defaultPasswordChange: PasswordChange = {
  current: '',
  update: '',
  confirm: '',
}