import {FC} from "react"
import {useTranslation} from "react-i18next"
import api from '../api'
import {state} from '../store'
import {useNavigate} from 'react-router'
import {useSearchParams} from 'react-router-dom'
import {Text, Card, Image, Flex, Stack} from '@mantine/core'
import PasswordChangeForm from '../forms/PasswordChangeForm'
import {PasswordChange} from '../entities/PasswordChange'
import authentication from '../authentication'
import Keyhole from '../keyhole.svg'

const PasswordChangeView: FC = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {signOut, idToken} = authentication()
  const [params] = useSearchParams()
  const tok = params.get('t') || idToken
  const requestCurrent = params.get('q') !== null
  state.updateRedirect(params.get('r') || '')
  const handlePasswordChange = async (passwordChange: PasswordChange) => {
    try {
      await api.changePassword(tok, passwordChange.current, passwordChange.update)
      signOut()
      navigate('/end', {state: {success: t('success_password_changed')}})
    } catch (e: any) {
      navigate('/end', {state: {error: t(e.message)}})
    }
  }
  return (
    <Flex h="100vh" justify="center" align="center">
      <Card shadow="lg" withBorder>
        <Stack align="center">
          <Image src={Keyhole} maw={30}/>
          <Text size="lg">{t('change_password')}</Text>
          <PasswordChangeForm requestCurrent={requestCurrent} onOk={handlePasswordChange} onCancel={() => navigate(-1)}/>
        </Stack>
      </Card>
    </Flex>
  )
}

export default PasswordChangeView