import {FC, Fragment, useEffect, useState} from "react";
import {Gate} from "../entities/Gate";
import api from "../api";
import {state} from "../store";
import {ActionIcon, Badge, Button, Card, Grid, Group, Modal, Stack, Text} from "@mantine/core";
import {IconCloud, IconLock, IconTrash, IconUser} from "@tabler/icons-react";
import GateForm from "../forms/GateForm";
import {defaultSearchResult} from "../entities/SearchResult";
import {useNavigate} from "react-router-dom";

const GatePage: FC<{ids: string}> = props => {
    const {ids} = props
    const idsAsNumberArray = ids.split(':').map(it => Number(it))
    const navigate = useNavigate()
    const [gates, setGates] = useState<Gate[]>([])
    const [edit, setEdit] = useState<Gate|undefined>()
    const handleOk = async (gate: Gate) => {
        try {
            const savedGate = await api.save<Gate>('gate', gate)
            setGates(gates.map(it => savedGate.id === it.id ? savedGate : it))
        } catch (e: any) {
            state.notifyError(e.message)
        }
        setEdit(undefined)
    }
    const handleDelete = async (gate: Gate) => {
        if (window.confirm(`Are you sure to delete gate ${gate.name}?`)){
            try {
                if (await api.deleteIds('gate', [gate.id])){
                    setGates(gates.filter(it => it.id !== gate.id))
                }
            } catch (e: any) {
                state.notifyError(e.message)
            }
        }
    }
    const handleGotoClient = (gate: Gate) => {
        state.updateSearchResult({...defaultSearchResult, clientIds: [gate.clientId]})
        navigate('/app/search-result/client')
    }
    const handleGotoAccount = (gate: Gate) => {
        state.updateSearchResult({...defaultSearchResult, accountIds: [gate.accountId]})
        navigate('/app/search-result/account')
    }

    useEffect(() => {
        (async () => {
            try {
                setGates((await api.entities<Gate>('gate', idsAsNumberArray)).sort((it1, it2) => idsAsNumberArray.indexOf(it1.id) - idsAsNumberArray.indexOf(it2.id)))
            } catch (e: any){
                state.notifyError(e.message)
            }
        })()
    }, [ids]);
    return (
        <Fragment>
            {gates.map(it => <Grid.Col key={it.id} span={{ base: 12, md: 6, lg: 3 }}>
                <Card shadow="md">
                    <Card.Section>
                        <Group justify="space-between" w="100%">
                            <Group gap={0}>
                                <Button variant="transparent" onClick={() => setEdit(it)}>{it.name}</Button>
                                {it.twoFactorAuthenticationEnabled && <Badge>2FA</Badge>}
                            </Group>
                            {!it.accessible && <IconLock size={16} color="red"/>}
                        </Group>
                    </Card.Section>
                    <Card.Section p="sm">
                        <Stack gap={0}>
                            {it.roles.length > 0 && <Text fz="xs">{`R: ${it.roles.join(' ')}`}</Text>}
                            {it.features.length > 0 && <Text fz="xs">{`F: ${it.features.join(' ')}`}</Text>}
                            {it.aspects.length > 0 && <Text fz="xs">{`A: ${it.aspects.join(' ')}`}</Text>}
                        </Stack>
                    </Card.Section>
                    <Card.Section>
                        <Group justify="space-between" w="100%">
                            <Group gap={2}>
                                <ActionIcon variant="transparent" onClick={() => handleGotoClient(it)}>
                                    <IconCloud size={16}/>
                                </ActionIcon>
                                <ActionIcon variant="transparent" onClick={() => handleGotoAccount(it)}>
                                    <IconUser size={16}/>
                                </ActionIcon>
                            </Group>
                            <ActionIcon variant="transparent" onClick={() => handleDelete(it)}>
                                <IconTrash size={16}/>
                            </ActionIcon>
                        </Group>
                    </Card.Section>
                </Card>
            </Grid.Col> )}
            <Modal opened={Boolean(edit)} onClose={() => setEdit(undefined)} title={`Gate: ${edit && edit.name}`}>
                <GateForm gate={edit} onCancel={() => setEdit(undefined)} onOk={handleOk}/>
            </Modal>
        </Fragment>
    )
}

export default GatePage
