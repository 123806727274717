import {FC} from 'react'
import './App.css'
import {Route, Routes} from 'react-router'
import MainLayout from './layouts/MainLayout'
import MainView from './views/MainView'
import TestView from './views/TestView'
import SignInView from './views/SignInView'
import PasswordChangeView from './views/PasswordChangeView'
import PasswordResetView from './views/PasswordResetView'
import AppLayout from './layouts/AppLayout'
import {BrowserRouter} from 'react-router-dom'
import SearchResultLayout from './layouts/SearchResultLayout'
import QueryView from './views/QueryView'
import ClientListView from './views/ClientListView'
import AccountListView from './views/AccountListView'
import EndView from './views/EndView'
import UserListView from './views/UserListView'
import GateListView from './views/GateListView'
import PasscodeConfirmView from './views/PasscodeConfirmView'

const App: FC = () => {
  return (
    <BrowserRouter basename={process.env.REACT_APP_WEB_CONTEXT}>
      <Routes>
        <Route path="/" element={<MainLayout/>}>
          <Route index element={<MainView/>}/>
          <Route path="test" element={<TestView/>}/>
          <Route path="sign-in" element={<SignInView/>}/>
          <Route path="passcode-confirm" element={<PasscodeConfirmView/>}/>
          <Route path="password-change" element={<PasswordChangeView/>}/>
          <Route path="password-reset" element={<PasswordResetView/>}/>
          <Route path="end" element={<EndView/>}/>
          <Route path="app" element={<AppLayout/>}>
            <Route path="search-result" element={<SearchResultLayout/>}>
              <Route index element={<QueryView/>}/>
              <Route path="query" element={<QueryView/>}/>
              <Route path="client" element={<ClientListView/>}/>
              <Route path="account" element={<AccountListView/>}/>
              <Route path="user" element={<UserListView/>}/>
              <Route path="gate" element={<GateListView/>}/>
            </Route>
            <Route path="test" element={<TestView/>}/>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}


export default App
