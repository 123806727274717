import authentication from './authentication'

class Client {
  private async rpc<T>(endPoint: string, method: string = 'GET', contentType: string = '', body: any | null = null): Promise<T> {
    const options: any = {
      method,
      headers: {
        'Accept': 'application/json',
        credentials: 'include',
      },
    }
    const {idToken} = authentication()
    if (idToken && idToken.length > 0){
      options.headers['Authorization'] = `Bearer ${idToken}`
    }
    if (body){
      if (contentType){
        options.headers['Content-Type'] = contentType
      }
      options.body = body;
    }
    // console.debug(JSON.stringify(options))
    const res = await fetch(endPoint, options)
    if (!res.ok){
/*
      if (res.status === 404 || res.status === 403){  // other error?
        signOut()
        window.location.replace(`${process.env.REACT_APP_WEB_CONTEXT}?error=${res.statusText}`)
      }
*/
      throw Error(`Http error: ${res.status} ${res.statusText}`)
    }
    const json = await res.json()
    if (typeof json === 'object' && 'error' in json) {
      const message = json.error
      console.error(json['error_description'])
      throw Error(message)
    }
    return json as T;
  }

  async get<T>(endPoint: string): Promise<T> {
    return this.rpc<T>(endPoint)
  }

  async post<T>(endPoint: string, data: any|FormData|URLSearchParams|string): Promise<T> {
    let contentType: undefined|string
    if (data instanceof FormData){
      contentType = undefined
    } else if (data instanceof URLSearchParams) {
      contentType = 'application/x-www-form-urlencoded'
    } else if (data instanceof String) {
      contentType = 'text/plain'
    } else {
      contentType = 'application/json'
      data = JSON.stringify(data)
    }
    return this.rpc<T>(endPoint, 'POST', contentType, data)
  }

  async put<T>(endPoint: string, data: any): Promise<T> {
    return this.rpc<T>(endPoint, 'PUT', 'application/json', JSON.stringify(data))
  }

  async delete<T>(endPoint: string, data: any): Promise<T> {
    return this.rpc<T>(endPoint, 'DELETE', 'application/json', JSON.stringify(data))
  }
}

const client = new Client()
export default client

