import {configureStore, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Feedback} from './entities/Feedback'
import {defaultPerpetual, Perpetual} from './entities/Perpetual'
import {defaultSearchResult, SearchResult} from './entities/SearchResult'

export interface ApplicationState {
  fetching: boolean,
  feedbacks: Feedback[],
  perpetual: Perpetual,
  searchResult: SearchResult,
  redirect: string,
}

const defaultApplicationState: ApplicationState = {
  fetching: false,
  feedbacks: [],
  perpetual: defaultPerpetual,
  searchResult: defaultSearchResult,
  redirect: '',
}

const applicationSlice = createSlice({
  name: 'application',
  initialState: defaultApplicationState,
  reducers: {
    updateFetching(state, action: PayloadAction<boolean>){
      return {...state, fetching: action.payload}
    },
    updateFeedbacks(state, action: PayloadAction<Feedback[]>){
      return {...state, feedbacks: action.payload}
    },
    updatePerpetual(state, action: PayloadAction<Perpetual>){
      return {...state, perpetual: action.payload}
    },
    updateSearchResult(state, action: PayloadAction<SearchResult>){
      return {...state, searchResult: action.payload}
    },
    updateRedirect(state, action: PayloadAction<string>){
      return {...state, redirect: action.payload}
    }
  }
})

// next functions (type: string, payload: any): only create actions
const {updateFetching, updateFeedbacks, updatePerpetual, updateSearchResult, updateRedirect} = applicationSlice.actions


export const store = configureStore({
  reducer:{
    application: applicationSlice.reducer
  }
})

export type RootState = ReturnType<typeof store.getState>

export const state = {
  updateFetching: (fetching: boolean) => store.dispatch(updateFetching(fetching)),
  updateSearchResult: (searchResult: SearchResult) => store.dispatch(updateSearchResult(searchResult)),
  updatePerpetual: (perpetual: Perpetual) => store.dispatch(updatePerpetual(perpetual)),
  updateRedirect: (redirect: string) => store.dispatch(updateRedirect(redirect)),
  notify: (feedback: Feedback, timeout: number = 2000) => {
    console.log(`Dispatching feedback: ${JSON.stringify(feedback)}`)
    const fs = store.getState().application.feedbacks
    store.dispatch(updateFeedbacks([...fs, feedback]))
    setTimeout(() => {
      const fs = store.getState().application.feedbacks
      store.dispatch(updateFeedbacks(fs.filter(it => it !== feedback)))
    }, timeout)
  },
  notifyError: (message: string, timeout: number = 2000) => state.notify({level: 'error', message}, timeout),
  notifySuccess: (message: string, timeout: number = 2000) => state.notify({level: 'success', message}, timeout),
  notifyWarning: (message: string, timeout: number = 2000) => state.notify({level: 'warning', message}, timeout),
  notifyInfo: (message: string, timeout: number = 2000) => state.notify({level: 'info', message}, timeout),
}
