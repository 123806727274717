import {FC} from 'react'
import {Button, Checkbox, Group, Stack, TextInput} from '@mantine/core'
import {Account, defaultAccount} from '../entities/Account'
import {useForm} from '@mantine/form'

interface AccountFormProps {
  account?: Account,
  onOk: (account: Account) => void,
  onCancel: () => void,
}

const AccountForm: FC<AccountFormProps> = props => {
  const {account, onOk, onCancel} = props
  const form = useForm<Account>({
    initialValues: account || defaultAccount,
    validate: {
      fullName: value => value.length === 0 ? 'Mandatory field' : null,
    }
  })
  return (
    <form noValidate onSubmit={form.onSubmit((values) => onOk(values))}>
      <Stack>
        <TextInput required label="Full name" {...form.getInputProps('fullName')}/>
        <Checkbox label="Locked" {...form.getInputProps('locked', { type: 'checkbox' })}/>
        <Group>
          <Button w={100} type="submit">OK</Button>
          <Button w={100} variant="outline" onClick={onCancel}>Cancel</Button>
        </Group>
      </Stack>
    </form>
  )
}

export default AccountForm